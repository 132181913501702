<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-reporte-escaner-export"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Reporte Escáner Guías
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="alert alert-warning text-white text-bold"
                        role="alert"
                      >
                        Este reporte genera un consolidado de imágenes / PDF's
                        comprimidos, por lo que la descarga puede tomar hasta 5
                        minutos. Por favor, tenga paciencia mientras se completa
                        el proceso.
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="alert alert-secondary text-white text-bold text-center col-md-11 p-0 mb-0 ml-3"
                      role="alert"
                    >
                      Seleccione un rango máximo de fechas de hasta 7 días.
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Fecha Inicial</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_inicial"
                        :class="
                          $v.filtros.fecha_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_final"
                        :class="
                          $v.filtros.fecha_final.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="n_guia" class="col-md-5">N° Guía</label>
                    <input
                      type="number"
                      id="n_guia"
                      v-model="filtros.n_guia"
                      class="col-md-7 form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="viaje_id" class="col-md-5">Viaje</label>
                    <input
                      type="number"
                      id="viaje_id"
                      v-model="filtros.viaje_id"
                      class="col-md-7 form-control form-control-sm"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="origen_id" class="col-md-5">Origen</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="origen"
                      placeholder="Producto pozo"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.sitiosOrigenes"
                      :filterable="true"
                      @input="getSelectSitioOrigen()"
                    >
                    </v-select>
                  </div>
                  <div class="form-group row">
                    <label for="destino_id" class="col-md-5">Destino</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="destino"
                      placeholder="Producto pozo"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.sitiosOrigenes"
                      :filterable="true"
                      @input="getSelectSitioDestino()"
                    >
                    </v-select>
                  </div>
                  <div class="form-group row">
                    <label for="transportadora_id" class="col-md-5"
                      >Empresa Transportadora</label
                    >
                    <v-select
                      class="form-control form-control-sm col-md-7 p-0"
                      v-model="empresa"
                      placeholder="Empresa"
                      label="razon_social"
                      :options="listasForms.empresas"
                      :filterable="true"
                      @input="getSelectEmpresa()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo_consulta" class="col-md-5"
                      >Tipo Consulta</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_consulta"
                      :class="
                        $v.filtros.tipo_consulta.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="listaConsulta in listasForms.listaConsultas"
                        :key="listaConsulta.numeracion"
                        :value="listaConsulta.numeracion"
                      >
                        {{ listaConsulta.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="placa" class="col-md-5">Placa Vehículo</label>
                    <input
                      class="form-control form-control-sm col-md-7 p-0"
                      v-model="filtros.vehiculo"
                      type="text"
                      id="vehiculo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                v-show="!$v.filtros.$invalid"
                @click="generarListadoExcel()"
                v-if="
                  $store.getters.can(
                    'hidrocarburos.reportes.exportReporteEcopetrol'
                  )
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "ReporteEscanerGuiasExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      vehiculo: [],
      origen: {},
      destino: {},
      empresa: {},
      filtros: {
        vehiculo: null,
        n_guia: null,
        viaje_id: null,
        empresa_id: null,
        origen_id: null,
        destino_id: null,
        fecha_inicial: "",
        fecha_final: "",
        tipo_consulta: null,
      },
      listasForms: {
        sitiosOrigenes: [],
        empresas: [],
        vehiculos: [],
        listaConsultas: [],
      },
    };
  },
  validations: {
    filtros: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
      tipo_consulta: {
        required,
      },
    },
  },
  methods: {
    validaFechas() {
      if (this.filtros.fecha_inicial) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.filtros.fecha_inicial);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.filtros.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        var fecha_menor = new Date(this.filtros.fecha_inicial);
        var fecha_mayor = new Date(this.filtros.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        fecha_menor = new Date(this.filtros.fecha_inicial).getTime();
        fecha_mayor = new Date(this.filtros.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 7) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 7 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_final) {
        var hoy = new Date();
        const fecha_final = new Date(this.filtros.fecha_final);
        fecha_final.setDate(fecha_final.getDate());
        if (fecha_final >= hoy) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha final debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    getSelectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
    },
    getSelectSitioOrigen() {
      this.filtros.origen_id = null;
      if (this.origen) {
        this.filtros.origen_id = this.origen.id;
      }
    },

    getSelectSitioDestino() {
      this.filtros.destino_id = null;
      if (this.destino) {
        this.filtros.destino_id = this.destino.id;
      }
    },
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/escanerGuias/exportReporteEscaner",
        data: this.filtros,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.cargando = false;
          if (!response.data.error) {
            if (response.data.byteLength > 0) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              console.log(url);
              link.href = url;
              link.setAttribute("download", "escaner_guias.zip");
              document.body.appendChild(link);
              link.click();

              this.$swal({
                icon: "success",
                title: "descarga completada..",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.$swal({
                icon: "warning",
                title: "No se encuentran escáner de guías..",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          } else {
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, intentar nuevamente... ",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    limpiarFiltros() {
      this.filtros.n_guia = null;
      this.filtros = {
        vehiculo: null,
        n_guia: null,
        empresa_id: null,
        origen_id: null,
        destino_id: null,
        fecha_inicial: "",
        fecha_final: "",
        tipo_consulta: null,
        viaje_id: null,
      };
      this.empresa = [];
      this.origen = [];
    },
  },

  async mounted() {
    this.cargando = true;
    this.cargando = false;
    this.limpiarFiltros();
  },
};
</script>
